<template>
  <div class="flex class">
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <div class="lg:px-6 lg:mx-2 pt-[75.6px]">
        <AdvertiseBannersWrapper />
        <NuxtPage />
      </div>
      <UNotifications :ui="uiNotifications" />
    </NuxtLayout>
  </div>
</template>

<script setup>
const colorMode = useColorMode();
colorMode.preference = "dark";
const { t } = useI18n({ useScope: "global" });
const toast = useToast();

const uiNotifications = {
  width: "w-full sm:w-[520px]",
};

const ui = {
  icon: {
    base: "flex-shrink-0 w-16 h-16",
    color: "text-{color}-500 dark:text-{color}-400",
  },
  title: "text-xl font-medium text-gray-900 dark:text-white",
  description: "mt-1 text-md leading-4 text-gray-500 dark:text-gray-400",
  wrapper: "w-full pointer-events-auto !border-primary !border-2",
};
const isOpen = ref(false);
onMounted(() => {
  toast.add({
    ui: ui,
    id: "cookie",
    title: computed(() => t("toast.cookie")),
    description: computed(() => t("toast.cookieDescription")),
    icon: "i-ph-cookie-bold",
    timeout: 0,
  });
});
</script>
