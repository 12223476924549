<script setup>
defineProps({
  data: Object,
});
import { useKeenSlider } from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";

const [container] = useKeenSlider(
  {
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      "(min-width: 400px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(min-width: 600px)": {
        slides: { perView: 3, spacing: 15 },
      },
    },
  },
  [
    (slider) => {
      let timeout;
      let mouseOver = false;
      function clearNextTimeout() {
        clearTimeout(timeout);
      }
      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 4000);
      }
      slider.on("created", () => {
        slider.container.addEventListener("mouseover", () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener("mouseout", () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });
      slider.on("dragStarted", clearNextTimeout);
      slider.on("animationEnded", nextTimeout);
      slider.on("updated", nextTimeout);
    },
  ]
);
</script>

<template>
  <div v-if="data.success" ref="container" class="keen-slider mt-4">
    <div class="keen-slider__slide" v-for="project in data.projects">
      <NuxtLink :to="project.project_link" target="_blank">
        <NuxtImg class="rounded-lg aspect-video md:max-h-72 max-h-24 md:min-h-44 min-h-24 w-full" format="webp"
          :src="project.link" alt="Banner Image" />
      </NuxtLink>
    </div>
  </div>
</template>
